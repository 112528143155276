import React from 'react';
import styled from 'styled-components';

const Representation = styled.nav``;

const Title = styled.h3`
	text-transform: uppercase;
	margin-bottom: 10px;
`;

const RepItem = styled.aside`
	display: flex;
	justify-content: center;
	padding-bottom: 5px;
	flex-wrap: wrap;
`;

const RepTitle = styled.h4`
	text-transform: uppercase;
	padding-right: 10px;
	padding-bottom: 5px;

	@media (min-width: 1024px) {
		padding-bottom: 0;
	}
`;

const RepContact = styled.a`
	color: inherit;
	text-decoration: none;
	font-family: 'TimesNow-Italic', 'Times New Roman', serif;
	font-style: italic;
`;

const RepresentationComponent = ({ reps }) => {
	return (
		<Representation>
			<Title>Representation</Title>
			{reps.map((rep, index) => (
				<RepItem key={index}>
					<RepTitle>
						{rep.location} / {rep.title}
					</RepTitle>
					<RepContact href={`mailto:${rep.email}`}>{rep.email}</RepContact>
				</RepItem>
			))}
		</Representation>
	);
};
export default RepresentationComponent;
