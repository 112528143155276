import React from 'react';
import styled from 'styled-components';

const Section = styled.span`
	padding-bottom: 5px;
	display: block;
	&::last-of-type {
		padding: 0;
	}

	@media (min-width: 1024px) {
		/* display: inline-flex;
		flex-wrap: wrap;
		text-align: center;
		justify-content: center;
		margin-right: 10px; */
	}
`;

const Title = styled.h2`
	padding-right: 10px;
	display: inline;
`;

const Project = styled.li`
	display: inline;
`;

const ProjectTitle = styled.span`
	display: inline-block;
	font-style: italic;
	padding-right: 5px;
	cursor: pointer;
	transition: color 0.25s ease;
	font-family: 'TimesNow-Italic', 'Times New Roman', serif;
	font-style: italic;

	@media (min-width: 1024px) {
		&.current {
			color: #f8df4f;
		}
	}
`;

const ProjectsCredit = ({
	title,
	projects,
	current,
	onMouseEnter,
	onClick,
}) => {
	return (
		<Section>
			<Title>{title}: </Title>
			{projects.map((proj, index) => (
				<Project key={index}>
					<ProjectTitle
						className={current.title === proj.title ? 'current' : ''}
						onMouseEnter={() =>
							onMouseEnter({
								title: proj.title,
								asset: proj.asset,
								poster: proj.poster,
								vimeo: proj.vimeo,
							})
						}
						onClick={() => onClick(proj.vimeo)}
						key={index}
					>
						{index !== projects.length - 1
							? `${proj.title.trim()},`
							: proj.title}
					</ProjectTitle>
				</Project>
			))}
		</Section>
	);
};

export default ProjectsCredit;
