import React, { useState } from 'react';
import { useStaticQuery, graphql, Link } from 'gatsby';
import styled from 'styled-components';
import Layout from '../components/Layout/Layout';
import ProjectCredits from '../components/Home/ProjectsCredits';
import Background from '../components/Home/Background';
import Representation from '../components/Home/Representation';
import VideoPlayer from '../components/Home/VideoPlayer';
import About from '../components/Home/About';
import SelectedPhotography from '../components/Photography/SelectedPhotography';
import SEO from '../components/seo';

const IndexWrapper = styled.main`
	position: fixed;
	top: 0;
	bottom: 0%;
	left: 0;
	right: 0%;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
`;

const CreditsWrapper = styled.section`
	color: white;
	width: 75%;
	text-align: center;
	z-index: 9;

	@media (min-width: 1024px) {
		width: 50%;
	}
`;

const TitleSection = styled.aside`
	margin-bottom: 20px;

	@media (min-width: 1024px) {
		margin-bottom: 40px;
	}
`;

const Name = styled.h1`
	text-transform: uppercase;
	font-weight: 500;
	padding-bottom: 5px;
	font-size: 24px;

	@media (min-width: 1024px) {
		font-size: 36px;
		padding-bottom: 15px;
	}
`;

const Roles = styled.ul`
	display: flex;
	justify-content: center;
`;

const Role = styled.li`
	font-family: 'TimesNow-Italic', 'Times New Roman', serif;
	font-style: italic;
	padding-right: 5px;

	&::last-of-type {
		padding-right: 0;
	}

	@media (min-width: 1024px) {
		font-size: 20px;
	}
`;

const Projects = styled(TitleSection)`
	@media (min-width: 1024px) {
		max-width: 800px;
		margin: 0 auto 20px;
	}
`;

const AdditionalLinks = styled.nav`
	margin-bottom: 20px;
`;

const CreditLink = styled(Link)`
	text-transform: uppercase;
	text-decoration: none;
	color: inherit;
`;

const Reps = styled(TitleSection)`
	margin-bottom: 0;
`;

const IndexPage = () => {
	const data = useStaticQuery(graphql`
		{
			allSanityHome {
				nodes {
					name
					titles
					sortedCategories {
						title
						projects {
							title
							vimeo
							asset {
								asset {
									url
								}
							}
							poster {
								asset {
									fluid {
										base64
										aspectRatio
										src
										srcSet
										srcWebp
										srcSetWebp
										sizes
									}
									url
								}
							}
						}
					}
					representation {
						title
						location
						email
						_key
					}
				}
			}
		}
	`);

	const [pageData] = useState(data.allSanityHome.nodes[0]);
	const [currentProject, setCurrentProject] = useState(
		pageData.sortedCategories[0].projects[0]
	);
	const [categories] = useState(
		pageData.sortedCategories.map((category) =>
			category.projects.map((proj) => proj)
		)
	);

	const [selectedPhotographyOpen, setSelectedPhotographyOpen] = useState(false);
	const [aboutOpen, setAboutOpen] = useState(false);
	const [currentOpenVideo, setCurrentOpenVideo] = useState(false);

	const videos = [];

	categories &&
		categories.forEach((category) => {
			// i'll figure out a cleaner way to iterate here
			category.forEach((project) => videos.push(project));
		});

	const setCurrent = ({ title, asset, poster, vimeo }) => {
		setCurrentProject({
			title,
			asset,
			vimeo,
			poster,
		});
	};

	const setVideo = (vimeo) => {
		setCurrentOpenVideo(vimeo);
	};

	return (
		<Layout>
			<SEO title="Keenan Wetzel" />
			<IndexWrapper>
				{categories.length > 0 && (
					<React.Fragment>
						{videos.map((item, index) => (
							<Background
								key={index}
								open={currentProject.asset.asset.url}
								asset={item.asset.asset.url}
								poster={item.poster.asset.url}
							/>
						))}
					</React.Fragment>
				)}
				<CreditsWrapper>
					<TitleSection>
						<Name>{pageData.name}</Name>
						{pageData.titles.length > 0 && (
							<Roles>
								{pageData.titles.map((title, index) => (
									<Role key={index}>
										{pageData.titles.length === 1 ||
										index === pageData.titles.length - 1
											? title.trim()
											: `${title.trim()},`}
									</Role>
								))}
							</Roles>
						)}
					</TitleSection>
					<Projects>
						{pageData.sortedCategories.length > 0 && (
							<React.Fragment>
								{pageData.sortedCategories.map((cat, index) => (
									<ProjectCredits
										key={index}
										title={cat.title}
										projects={cat.projects}
										current={currentProject}
										onMouseEnter={setCurrent}
										onClick={setVideo}
									/>
								))}
							</React.Fragment>
						)}
					</Projects>
					<AdditionalLinks>
						<CreditLink to={'/'} onClick={() => setAboutOpen(true)}>
							Information
						</CreditLink>
						{' / '}
						<CreditLink
							to={'/'}
							onClick={() => setSelectedPhotographyOpen(true)}
						>
							Selected Photography
						</CreditLink>
					</AdditionalLinks>
					<Reps>
						<Representation reps={pageData.representation} />
					</Reps>
				</CreditsWrapper>

				{pageData.sortedCategories.length > 0 && (
					<React.Fragment>
						{pageData.sortedCategories.map((cat, index) => {
							return cat.projects.map((item, index) => (
								<VideoPlayer
									key={index}
									open={currentOpenVideo}
									title={item.title}
									asset={item.vimeo}
									onClick={setVideo}
								/>
							));
						})}
					</React.Fragment>
				)}
				<SelectedPhotography
					open={selectedPhotographyOpen}
					onClick={() => setSelectedPhotographyOpen(false)}
				/>
				<About open={aboutOpen} onClick={() => setAboutOpen(false)} />
			</IndexWrapper>
		</Layout>
	);
};

export default IndexPage;
