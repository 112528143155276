import React, { useMemo, useState } from 'react';
import styled from 'styled-components';
import Video from './Video';

const Background = styled.section`
	display: flex;
	justify-content: center;
	flex-direction: column;
	text-align: center;
	transition: 0.5s opacity ease;
	opacity: 0;
	position: fixed;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	background-color: black;
	opacity: 0;

	&.current {
		opacity: 1;
	}

	video {
		object-fit: cover;
		width: 100vw;
		height: 100%;
		position: fixed;
		top: 0;
		left: 0;
		transition: 0.75s opacity ease;
		opacity: 0;
		&.current {
			opacity: 1;
		}

		@media (min-width: 1024px) {
			height: 100vh;
		}
	}
`;
const BackgroundAsset = ({ asset, open, poster }) => {
	const match = useMemo(() => {
		return asset === open;
	}, [open]);
	return (
		<Background className={match ? 'current' : ''}>
			<Video current={open} src={asset} poster={poster} />
		</Background>
	);
};

export default BackgroundAsset;
