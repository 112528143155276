import React, { useState } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import styled from 'styled-components';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Slider from 'react-slick';
import ArrowBlack from '../../images/arrow-black.svg';

const SelectedPhotography = styled.section`
	position: absolute;
	top: 0;
	right: 0;
	left: 0;
	bottom: 0;

	overflow-y: scroll;

	background-color: white;
	color: black;
	transform: translateX(100%);
	transition-timing-function: cubic-bezier(0.39, 0, 0.72, 0.12);
	transition: transform 0.5s;
	z-index: 9;

	&.open {
		transform: translateX(0);
	}
`;

const Header = styled.header`
	padding: 20px;
	display: flex;
	justify-content: space-between;
`;

const HeaderTitle = styled.h3`
	text-transform: uppercase;

	@media (min-width: 1024px) {
		font-size: 18px;
	}
`;

const CloseButton = styled.button`
	border: 0;
	background-color: transparent;
	padding: 0;
	font-size: 1em;
	text-transform: uppercase;
	-webkit-tap-highlight-color: rgba(0, 0, 0, 0);
	cursor: pointer;

	&:focus {
		outline: 1px solid transparent;
	}

	@media (min-width: 1024px) {
		font-size: 18px;
	}
`;

const SliderSection = styled.aside`
	max-width: calc(100vw - 40px);
	max-height: calc(100vh - 160px);
	display: flex;
	flex-direction: column;
	justify-content: center;
	height: 100%;
	margin: 0 auto;

	.slick-prev,
	.slick-next {
		width: 20px;
		height: 20px;
		top: 0;
		bottom: 0;
		height: auto;
		z-index: 2;
		transform: translate(0, 0);

		&:before {
			display: block;
			content: '';
			height: 20px;
			width: 20px;
			background-position: center;
			background-repeat: no-repeat;
			background-size: contain;
		}
	}

	.slick-prev {
		left: 10px;
		&:before {
			background-image: url(${ArrowBlack});
			transform: rotate(180deg);
		}
	}
	.slick-next {
		right: 10px;
		&:before {
			background-image: url(${ArrowBlack});
			margin-left: auto;
			margin-right: 20px;
		}
	}

	@media (min-width: 1024px) {
		.slick-prev,
		.slick-next {
			width: 40px;
			height: 40px;
			top: 0;
			bottom: 0;
			height: auto;
			&:before {
				height: 40px;
				width: 40px;
			}
		}
		.slick-prev {
			left: 20px;
		}
		.slick-next {
			right: 20px;
		}
	}
`;

const Slide = styled.div`
	max-height: calc(100vh - 160px) !important;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;

	padding: 40px;
	position: relative;
	img {
		max-width: 100%;
		width: auto;
		margin: 0 auto;
		max-height: calc(100vh - 250px);
	}
`;

const Caption = styled.p`
	position: absolute;
	bottom: 0px;
	left: 0;
	right: 0;
	text-transform: uppercase;
	text-align: center;
	padding-top: 20px;
`;

const SelectedPhotographyComponent = ({ open, onClick }) => {
	const data = useStaticQuery(graphql`
		{
			allSanitySelectedPhotography {
				nodes {
					title
					photographs {
						caption
						_key
						image {
							asset {
								url
								fluid {
									base64
									aspectRatio
									src
									srcSet
									srcWebp
									srcSetWebp
									sizes
								}
							}
						}
					}
				}
			}
		}
	`);

	const [photos] = useState(
		data.allSanitySelectedPhotography.nodes[0].photographs
	);

	const slickSettings = {
		dots: false,
		infinite: true,
		fade: true,
		speed: 750,
		slidesToShow: 1,
		slidesToScroll: 1,
		adaptiveHeight: true,
	};

	return (
		<SelectedPhotography className={open ? 'open' : 'closed'}>
			<Header>
				<HeaderTitle>Keenan Wetzel</HeaderTitle>
				<CloseButton onClick={onClick}>Close</CloseButton>
			</Header>
			{photos.length > 0 && (
				<SliderSection>
					<Slider {...slickSettings}>
						{photos.map((pic) => (
							<Slide key={pic._key}>
								<img src={pic.image.asset.url} alt={pic.caption} />
								<Caption>{pic.caption}</Caption>
							</Slide>
						))}
					</Slider>
				</SliderSection>
			)}
		</SelectedPhotography>
	);
};

export default SelectedPhotographyComponent;
