import React, { useState } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import styled from 'styled-components';
import BlockContent from '@sanity/block-content-to-react';

const About = styled.section`
	position: fixed;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	background-color: rgba(255, 255, 255, 0.9);
	color: black;
	z-index: 0;
	opacity: 0;
	transition: opacity 0.5s;

	&.open {
		opacity: 1;
		z-index: 9999;
		button {
			cursor: pointer;
		}
	}
`;
const Header = styled.header`
	display: flex;
	justify-content: space-between;
	align-content: center;
	padding: 20px;
`;

const HeaderTitle = styled.p`
	padding-top: 4px;
`;

const CloseButton = styled.button`
	padding: 0;
	border: 0;
	margin: 0;
	border-radius: 0;
	background-color: transparent;
	font-family: neue-haas-unica, 'Helvetica', 'Arial', sans-serif;
	font-display: swap;
	font-size: 1em;
	-webkit-appearance: none;
	-webkit-border-radius: 0;

	&:focus {
		outline: 1px solid transparent;
	}
`;

const AboutContent = styled.section`
	padding: 20px;
`;

const AboutText = styled.aside`
	max-width: 75ch;

	p::first-of-type {
		margin-top: 0;
	}

	p,
	em,
	strong {
		margin-bottom: 1em;
	}

	em {
		font-style: italic;
	}
	@media (min-width: 1024px) {
		width: 50%;
	}
`;

const AboutLink = styled.a`
	color: inherit;
	text-decoration: none;

	&.about-contact {
		display: block;
	}

	&:hover {
		text-decoration: underline;
	}
`;

const ContactTitle = styled.p`
	text-transform: uppercase;
	margin-bottom: 0.5em;
`;

const AboutComponent = ({ open, onClick }) => {
	const data = useStaticQuery(graphql`
		{
			allSanityHome {
				nodes {
					_rawAbout
					instagram
					email
				}
			}
		}
	`);

	const aboutText = data.allSanityHome.nodes[0]._rawAbout;
	const instagram = data.allSanityHome.nodes[0].instagram;
	const email = data.allSanityHome.nodes[0].email;
	return (
		<About className={open ? 'open' : 'closed'}>
			<Header>
				<HeaderTitle>Keenan Wetzel — Information</HeaderTitle>
				<CloseButton onClick={onClick}>Close</CloseButton>
			</Header>
			<AboutContent>
				{aboutText && (
					<AboutText>
						<BlockContent blocks={aboutText} />
					</AboutText>
				)}
				{(instagram || email) && <ContactTitle>Contact</ContactTitle>}
				{instagram && (
					<AboutLink
						href={instagram}
						target={'_blank'}
						rel={'noreferrer noopener'}
						className="about-contact"
					>
						Instagram
					</AboutLink>
				)}
				{instagram && (
					<AboutLink
						href={`mailto:${email}`}
						target={'_blank'}
						rel={'noreferrer noopener'}
						className="about-contact"
					>
						{email}
					</AboutLink>
				)}
			</AboutContent>
		</About>
	);
};

export default AboutComponent;
