import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import ReactPlayer from 'react-player';
import Close from '../../images/close-white.svg';

const VideoPlayer = styled.article`
	visibility: hidden;
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: rgba(0, 0, 0, 0.8);
	opacity: 0;
	transition: opacity 0.5s ease;
	display: flex;
	flex-direction: column;
	justify-content: center;

	&.open {
		z-index: 999;
		opacity: 1;
		visibility: visible;
		@media (min-width: 1024px) {
			padding: 150px;
		}
	}
`;

const Title = styled.h2`
	position: absolute;
	bottom: 50px;
	text-align: center;
	text-transform: uppercase;
	color: white;
	left: 0;
	right: 0;

	@media (min-width: 1024px) {
		bottom: 30px;
	}
`;

const CloseIcon = styled.img`
	position: absolute;
	top: 10px;
	right: 10px;
	width: 40px;
	height: 40px;

	@media (min-width: 1024px) {
		top: 20px;
		right: 20px;
		cursor: pointer;
	}
`;

const PlayerWrapper = styled.div`
	position: relative;
	padding-top: 56.25%; /* Player ratio: 100 / (1280 / 720) */
`;

const Player = styled(ReactPlayer)`
	position: absolute;
	top: 0;
	left: 0;
`;

const VideoPlayerComponent = ({ title, open, asset, onClick }) => {
	const [openPlayer, setOpenPlayer] = useState(null);

	useEffect(() => {
		setOpenPlayer(open);
		return () => {
			setOpenPlayer(false);
		};
	}, [open, asset, title]);

	return (
		<VideoPlayer className={open === asset ? 'open' : ''}>
			<CloseIcon src={Close} onClick={() => onClick(false)} />
			{asset && (
				<PlayerWrapper>
					<Player url={asset} width="100%" height="100%" controls={true} />
				</PlayerWrapper>
			)}
			<Title>{title}</Title>
		</VideoPlayer>
	);
};

export default VideoPlayerComponent;
