import React from 'react';

export default class Video extends React.Component {
	constructor(props) {
		super(props);
		this.videoRef = React.createRef();
		this.playVideoRef = this.playVideoRef.bind(this);
		this.state = {
			current: false,
		};
	}

	playVideoRef() {
		// This was required to get it to work for mobile iOS
		this.videoRef.current.autoplay = true;
		this.videoRef.current.focus();
		this.videoRef.current.play();
		this.setState({ current: true });
	}

	componentDidMount() {
		if (this.props.current === this.props.src) {
			this.videoRef.current.autoplay = true;
			this.videoRef.current.focus();
			this.videoRef.current.play();
		}
	}

	componentDidUpdate() {
		if (this.props.current !== this.props.src) {
			this.videoRef.current.pause();
		} else {
			this.videoRef.current.autoplay = true;
			this.videoRef.current.focus();
			this.videoRef.current.play();
		}
	}

	componentWillUnmount() {
		this.videoRef.current.pause();
	}

	render() {
		return (
			<video
				ref={this.videoRef}
				src={this.props.src}
				poster={this.props.poster}
				type="video/mp4"
				autoPlay={false}
				muted={true}
				loop={true}
				playsInline
				className={'current'}
			/>
		);
	}
}
